var generic = generic || {};
$(document).ready(function() {
  var zdc = {};
  zdc.rb = generic.rb('error_messages');
  var zdc_error_response = zdc.rb.get('zdc_out_of_stock');
  $('.addZdcOfferToBag').on('click', function(e) {
    var zdc_redeemed = 0;
    e.preventDefault();
    var offerCode = $(this).data('offer');
    generic.jsonrpc.fetch({
      method: 'rpc.form',
      params: [ {
        _SUBMIT: 'offer_code',
        OFFER_CODE: offerCode
      }],
      onSuccess: function() {
        var containerDiv = $('<div/>', { 'class': 'overlay-content' });
        $('body').append(containerDiv);
        $.ajax('/templates/zero_dollar_checkout_popup.tmpl', {
          method: 'get',
          success: function(transport) {
            containerDiv.append(transport);
            generic.overlay.launch({
              content: containerDiv,
              includeBackground: true,
              cssStyle: {height: '300px'},
              cssClass: ''
            });
          }
        });
      },
      onFailure: function(jsonRpcResponse) {
        var errorObj = jsonRpcResponse.getError();
        var msgObj = jsonRpcResponse.getData();
        var error_messages = '';
        if (msgObj && msgObj.messages) {
          zdc_error_response = zdc.rb.get('zdc_applied');
          $(msgObj.messages).each(function(index, responseMessages) {
            if (responseMessages.key.indexOf('offer_criteria_not_met') !== -1) {
              error_messages += responseMessages.text + '<br/>';
              zdc_redeemed = 1;
            } else {
              error_messages += zdc_error_response + '<br/>';
              return false;
            }
          });
        } else if (errorObj && errorObj.data && errorObj.data.messages) {
          $(errorObj.data.messages).each(function(index, errorMessages) {
            if (errorMessages.key.indexOf('out_of_stock') !== -1) {
              error_messages += zdc_error_response + '<br/>';
              return false;
            } else {
              error_messages += errorMessages.text + '<br/>';
            }
          });
        }
        var containerDiv = $('<div/>', { 'class': 'overlay-content' });
        $('body').append(containerDiv);
        $.ajax('/templates/zero_dollar_checkout_popup.tmpl', {
          method: 'get',
          success: function(transport) {
            containerDiv.append(transport);
            $('.popup_content_text.zero-dollar-success').addClass('hidden');
            $('.popup_content_text.zero-dollar-error').removeClass('hidden').html(error_messages);
            if (zdc_redeemed) {
              $('.checkout-buttons').addClass('hidden');
            }
            generic.overlay.launch({
              content: containerDiv,
              includeBackground: true,
              cssStyle: {height: '400px'},
              cssClass: ''
            });
          }
        });
      }
    });
  });
});
